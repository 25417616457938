import mapVerify from '@/utils/formValidate'

// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 字典编码
    {
      prop: 'dictCode',
      label: that.$t('lang.gles.base.dictCode'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 字典名称
    {
      prop: 'dictName',
      label: that.$t('lang.gles.base.dictName'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 字典描述
    {
      prop: 'dictDescription',
      label: that.$t('lang.gles.base.dictDescription'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 状态
    {
      prop: 'dictStatus',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      showOverflowTooltip: true,
      slotName: 'dictStatus',
      minWidth: 84
    },
    // 创建人
    {
      prop: 'createUser',
      label: that.$t('lang.gles.common.createUser'),
      isShow: true,
      width: 100
    },
    // 创建时间
    {
      prop: 'createTime',
      label: that.$t('lang.gles.common.createTime'),
      isShow: true,
      minWidth: 180
    },
    // 编辑人
    {
      prop: 'updateUser',
      label: that.$t('lang.gles.common.updateUser'),
      isShow: true
    },
    // 编辑时间
    {
      prop: 'updateTime',
      label: that.$t('lang.gles.common.updateTime'),
      isShow: true,
      minWidth: 180
    },
    // 备注
    {
      prop: 'dictRemark',
      label: that.$t('lang.gles.base.dictRemark'),
      isShow: true,
      minWidth: 180
    }
  ]
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 字典名称
    {
      name: 'dictName',
      value: row.dictName || '',
      span: 8,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.dictName'),
      rules: mapVerify(['required', 'inputName'])
    },
    // 字典编码
    {
      name: 'dictCode',
      value: row.dictCode || '',
      span: 8,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.dictCode'),
      rules: mapVerify(['required', 'inputCode'])
    },
    // 字典描述
    {
      name: 'dictDescription',
      value: row.dictDescription || '',
      span: 8,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.dictDescription'),
      rules: mapVerify(['inputLen200'])
    },
    // 备注
    {
      name: 'dictRemark',
      value: row.dictRemark || '',
      span: 8,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.dictRemark'),
      rules: mapVerify(['inputLen200'])
    },
    // 字典状态
    {
      name: 'dictStatus',
      value: row.dictStatus ?? 0,
      span: 8,
      component: 'elSwitch',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.usableFlag'),
      activeValue: 0,
      inactiveValue: 1,
      activeText: that.$t('lang.gles.common.enable'),
      inactiveText: that.$t('lang.gles.common.disable'),
      class: 'switch'
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
      item.disabled = true
    }
  })
  return formData
}

// 明细列表
export const getDetailTableItem = (that) => {
  const searchTableItem = [
    // 参数名称
    {
      prop: 'detailName',
      label: that.$t('lang.gles.base.paramName'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 参数编码
    {
      prop: 'detailCode',
      label: that.$t('lang.gles.base.paramCode'),
      isShow: true,
      showOverflowTooltip: true,
      sortable: true
    },
    // 顺序
    {
      prop: 'detailOrder',
      label: that.$t('lang.gles.base.paramOrder'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 字典描述
    {
      prop: 'detailDescription',
      label: that.$t('lang.gles.base.dictDetailDescription'),
      isShow: true,
      showOverflowTooltip: true
    },
    // 状态
    {
      prop: 'detailStatus',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      showOverflowTooltip: true,
      slotName: 'detailStatus'
    },
    // 备注
    {
      prop: 'detailRemark',
      label: that.$t('lang.gles.base.dictDetailRemark'),
      isShow: true,
      showOverflowTooltip: true
    }
  ]
  return searchTableItem
}

// 编辑弹框 基础信息
export const getDetailFormData = (that, row) => {
  const formData = [
    // 参数名称
    {
      name: 'detailName',
      value: that.$t(row.detailName) || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.paramName'),
      rules: mapVerify(['required', 'inputName']),
      disabled: that.mode !== 'add'
    },
    // 参数编码
    {
      name: 'detailCode',
      value: row.detailCode || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.paramCode'),
      rules: mapVerify(['required', 'inputCode']),
      disabled: that.mode !== 'add'
    },
    // 顺序
    {
      name: 'detailOrder',
      value: row.detailOrder || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.paramOrder'),
      rules: mapVerify(['positiveInteger']),
      disabled: that.mode !== 'add'
    },
    {
      name: 'detailDescription',
      value: row.detailDescription || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.dictDetailDescription'),
      rules: mapVerify(['inputLen200']),
      disabled: that.mode !== 'add'
    },
    // 备注
    {
      name: 'detailRemark',
      value: row.detailRemark || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.dictDetailRemark'),
      rules: mapVerify(['inputLen200']),
      disabled: that.mode !== 'add'
    },
    // 字典状态
    {
      name: 'detailStatus',
      value: row.detailStatus || 0,
      span: 24,
      component: 'elSwitch',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.usableFlag'),
      activeText: that.$t('lang.gles.common.enable'),
      inactiveText: that.$t('lang.gles.common.disable'),
      activeValue: 0,
      inactiveValue: 1,
      class: 'switch'
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}
