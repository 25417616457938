<template>
  <m-dialog
    :visible="visible"
    :title="title"
    width="500px"
    :is-need-footer="false"
    @closed="handleCancel"
  >
    <m-form
      ref="myForm"
      :form-data="baseFormData"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
      class="basic-data-add-form"
    />
    <div class="btns" style="text-align: center">
      <el-button v-debounce="handleSave" type="primary">
        {{ $t('lang.gles.common.save') }}
      </el-button>
      <el-button @click="handleCancel">
        {{ $t('lang.gles.common.cancel') }}
      </el-button>
    </div>
  </m-dialog>
</template>
<script>
import addMixins from '@/mixins/addMixins'
import { getDetailFormData } from '../data'
export default {
  mixins: [addMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      extendFormConfig: {
        isNeedBtn: false
      },
      formModel: {}
    }
  },
  computed: {
    title() {
      return this.mode === 'add'
        ? this.$t('lang.gles.base.addParams')
        : this.$t('lang.gles.base.editParams')
    },
    baseFormData() {
      return getDetailFormData(this, { ...this.formModel })
    }
  },
  watch: {
    rowDetail() {
      this.formModel = { ...this.rowDetail }
    }
  },
  created() {
    this.formModel = { ...this.rowDetail }
  },
  methods: {
    handleSave() {
      this.$refs.myForm.getValidateFormModel().then(async (model) => {
        this.insert({ ...this.rowDetail })
      })
    },
    /**
     * 新增数据字典明细
     * @param {*} params
     */
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('addDictDetail'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.$parent.getDetail()
      this.handleCancel()
    },
    /**
     * 取消
     */
    handleCancel() {
      this.$emit('update:row-detail', {})
      this.$refs.myForm.resetForm()
      this.$emit('update:visible', false)
    }
  }
}
</script>
