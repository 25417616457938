<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :is-show-more-search="false"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCode')"
      @baseSearch="handleBaseSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @pageChange="pageChange"
      >
        <template #dictStatus="{ row }">
          <el-switch
            v-model="row.dictStatus"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            disabled
          />
        </template>
      </m-table>
    </el-card>
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getSearchTableItem } from '../data'
import listMixins from '@/mixins/listMixins'
import QueryView from '@/components/moreQuery/queryView.vue'

export default {
  name: 'BaseDictList',
  components: {
    ColumnConfig,
    MTable,
    QueryView
  },
  mixins: [listMixins],
  data() {
    return {
      tableData: [],
      baseFormModel: {
        codeOrName: ''
      },
      formModel: {},
      tableExtendConfig: {
        sortNum: true,
        checkBox: false,
        operateWidth: '60px',
        operate: [
          {
            event: 'editItem',
            label: this.$t('lang.gles.common.edit')
          }
        ]
      }
    }
  },
  computed: {
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      return getSearchTableItem(this, {}, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  async created() {
    this.doSearch(this.baseFormModel)
  },
  methods: {
    // 查询
    async doSearch(params, api = 'getDictList') {
      const { data, code } = await this.$httpService(
        this.$apiStore.base(api, this.pageData),
        params
      )
      if (code) return
      const {
        recordList = [],
        currentPage,
        pageSize,
        totalRecordCount: recordCount
      } = data || {}
      this.pageData = { currentPage, pageSize, recordCount }
      recordList?.forEach((item) => {
        item.dictName = this.$t(item.dictName)
      })
      this.tableData = recordList || []
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 编辑
     */
    editItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'BaseDictAdd',
        mode: 'edit',
        row
      })
    }
  }
}
</script>
