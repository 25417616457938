<template>
  <div class="basic-data-add">
    <header-path :paths="headerPaths" />
    <el-card class="add-card">
      <h3 class="title">
        {{ $t('lang.gles.common.basicInfo') }}
      </h3>
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        label-position="right"
        :label-width="100"
        :extend-config="extendFormConfig"
      />
      <el-button type="primary" @click="handleCancel">
        {{ $t('lang.gles.common.back') }}
      </el-button>
    </el-card>
    <el-card class="add-card" style="margin-top: 10px">
      <div class="title-wrapper" style="justify-content: space-between">
        <h3 class="title">{{ $t('lang.gles.base.paramList') }}</h3>
        <el-button type="primary" icon="el-icon-plus" @click="handleAddDetail">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @pageChange="pageChange"
      >
        <template #detailStatus="{ row }">
          <el-switch
            :value="row.detailStatus"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            disabled
            @click.native="handleChangeDetailStatus(row)"
          />
        </template>
      </m-table>
    </el-card>
    <add-dialog
      :visible.sync="addDialogVisible"
      :mode="detailMode"
      :row-detail.sync="detailRow"
    />
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import { getEditBaseFormData, getDetailTableItem } from '../data'
import addMixins from '@/mixins/addMixins'
import addDialog from './addDetail.vue'

export default {
  name: 'BaseDictAdd',
  components: {
    MForm,
    HeaderPath,
    addDialog
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      extendFormConfig: {
        isNeedBtn: false
      },
      tableExtendConfig: {
        sortNum: true
      },
      tableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10
      },
      addDialogVisible: false,
      detailMode: 'add',
      detailRow: {}
    }
  },
  computed: {
    headerPaths() {
      return this.mode === 'add'
        ? [this.$t('lang.gles.dict.baseDictAdd')]
        : [this.$t('lang.gles.dict.baseDictEdit')]
    },
    baseFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...row })
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      const methods = {
        linefeed: this.linefeed
      }
      return getDetailTableItem(this, {}, methods)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    /**
     * 获取字典详情
     */
    async getDetail() {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('queryDictDetailByDictCode'),
        { dictCode: this.rowDetail.dictCode }
      )
      if (code) return
      data?.forEach((item) => {
        item.detailName = this.$t(item.detailName)
      })
      this.totalDetailList = data || []
      this.pageData = {
        ...this.pageData,
        recordCount: data?.length
      }
      this.tableData = this.getCurrentPageData()
    },
    /**
     * 分页切换
     */
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.tableData = this.getCurrentPageData()
    },
    /**
     * 获取当前页数据
     */
    getCurrentPageData() {
      const { currentPage, pageSize } = this.pageData
      return (
        this.totalDetailList.filter((item, i) => {
          const startIdx = (currentPage - 1) * pageSize
          const endIdx = currentPage * pageSize
          const idx = i + 1
          return idx > startIdx && idx <= endIdx
        }) ?? []
      )
    },
    handleAddDetail() {
      this.detailRow = {
        dictCode: this.rowDetail.dictCode,
        detailStatus: 0
      }
      this.addDialogVisible = true
    },
    /**
     * 启用/禁用数据字典
     */
    handleChangeDetailStatus(row) {
      const confirmMsg = this.$t('lang.gles.common.wantEnable/Disable')
      this.$confirm(confirmMsg, '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.updateDetailStatus(row)
      })
      return
    },
    /**
     * 接口更新字典启用/禁用状态
     */
    async updateDetailStatus(row) {
      const { code } = await this.$httpService(
        this.$apiStore.base('changeDetailStatus'),
        {
          id: row.id,
          detailStatus: row.detailStatus ? 0 : 1
        }
      )
      if (code) return
      this.getDetail()
    },
    handleCancel() {
      this.$emit('updateCom', {
        currentCom: 'BaseDictList',
        mode: ''
      })
    }
  }
}
</script>
